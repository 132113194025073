.navbarStyle {
  margin-bottom: 2%;
  border-bottom: 1mm solid;
  border-color: #73b1b5;
}

.navbarText {
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  left: 200px;
  top: 15px;
}

.navbarIncidentButton:hover {
    background-color: #004f55;
    color: white;
}

.navbarIncidentButton {
    transition-duration: 0.4s;
    margin-right: 10px;
    box-sizing: content-box;
    height: 20px;
    font-weight: bold;
    background-color: #007079;
    position: absolute;
    left: 200px;
    top: 70px;
}

.navbarCreateIncidentButton:hover {
    background-color: #004f55;
    color: white;
}

.navbarCreateIncidentButton {
    transition-duration: 0.4s;
    margin-left: 10px;
    box-sizing: content-box;
    height: 20px;
    font-weight: bold;
    background-color: #007079;
    position: absolute;
    left: 292px;
    top: 70px;
}

.navbarApproveIncidentButton:hover {
    background-color: #004f55;
    color: white;
}

.navbarApproveIncidentButton {
    transition-duration: 0.4s;
    margin-left: 10px;
    box-sizing: content-box;
    height: 20px;
    font-weight: bold;
    background-color: #007079;
    position: absolute;
    left: 450px;
    top: 70px;
}

.background{
    background-color: #f7f7f7;
}
