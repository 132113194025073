.eventBorder{
    border-style: solid;
    border-width: 3px;
    border-color: #a8ced1;
    border-radius: 10px;
}

.eventRemoveButton:hover {
    background-color: #b22323;
}

.eventRemoveButton {
    transition-duration: 0.4s;
    box-sizing: content-box;
    background-color: #e52d2d;
}