.incidentBorder {
  border-style: solid;
  border-width: 3px;
  border-color: #73b1b5;
  border-radius: 10px;
}

.eventBorder {
  border-style: solid;
  border-width: 3px;
  border-color: #a8ced1;
  border-radius: 10px;
}
