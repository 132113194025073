.createStyling {
  margin: 2%;
}

.createAlign{
  text-indent: 2%;
}

.createPageButton:hover {
  background-color: #004f55;
}

.createPageButton {
  transition-duration: 0.4s;
  box-sizing: content-box;
  background-color: #007079;
  margin: 20px;
}

.createPageStyling {
  margin: 0;
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
}
